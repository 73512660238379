@charset "UTF-8";
@use 'global/icons';
@use 'global/icons_classes';
@use 'global/colors';
@use 'global/elevation';

$normal: normal;

.icon.livejasmin {
    --scheme-muted-color: #{colors.get(pink, 500)};
    --scheme-muted-with-hover-color: rgb(var(--color-primary-0));
    --scheme-muted-with-light-hover-color: #{colors.get(neutral, 100)};
}

.icon.oranum {
    --scheme-muted-color: #{colors.get(purple, 500)};
    --scheme-muted-with-hover-color: rgb(var(--color-primary));
    --scheme-muted-with-light-hover-color: #{colors.get(purple, 700)};
}

/* stylelint-disable property-no-vendor-prefix, selector-pseudo-element-colon-notation */
.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'msc-icons' !important;
    speak: never;
    font-style: $normal;
    font-weight: $normal;
    font-variant: $normal;
    text-transform: none;
    line-height: 1;

    transition-duration: 0.15s;
    transition-property: background, color, opacity;
    transition-timing-function: ease-in;
    vertical-align: middle;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.with-gradient {
    background-clip: text !important;
    color: transparent !important;
    -webkit-text-fill-color: transparent !important;
    -webkit-background-clip: text !important;
}

.transparentUnderlay {
    position: relative;

    &::before {
        position: relative;
        z-index: 1;
    }

    &:after {
        content: '';
        position: absolute;
        z-index: 0;
        left: 10%;
        top: 10%;
        width: 80%;
        height: 80%;
        border-radius: 50%;
        box-shadow: elevation.get(dark_level_01);
        background: #{colors.get(neutral, 900, 0.5)};
    }
}

.iconColorSchemeMuted,
.iconColorSchemeMutedWithHover,
.iconColorSchemeMutedWithLightHover {
    color: var(--scheme-muted-color);
}

.iconColorSchemeMutedWithHover:hover {
    color: var(--scheme-muted-with-hover-color);
}

.iconColorSchemeMutedWithLightHover:hover {
    color: var(--scheme-muted-with-light-hover-color);
}
