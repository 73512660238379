$font-family-roboto: 'Roboto', Arial, sans-serif;

$font-weight-ultra-bold: 900 !default;
$font-weight-extra-bold: 800 !default;
$font-weight-bold: 700 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-medium: 500 !default;
$font-weight-regular: 400 !default;
$font-weight-light: 300 !default;
$font-weight-extra-light: 200 !default;
$font-weight-thin: 100 !default;

$font-size-display1: 4.8rem !default;
$font-size-display2: 4rem !default;
$font-size-display3: 3.6rem !default;
$font-size-h1: 3.2rem !default;
$font-size-h2: 2.8rem !default;
$font-size-h3: 2.4rem !default;
$font-size-title1: 2rem !default;
$font-size-title2: 1.6rem !default;
$font-size-body1: 1.6rem !default;
$font-size-body2: 1.4rem !default;
$font-size-caption: 1.2rem !default;
$font-size-fineprint1: 1rem !default;
$font-size-fineprint2: 0.8rem !default;

$line-height-display1: 5.6rem !default;
$line-height-display2: 4.4rem !default;
$line-height-display3: 4rem !default;
$line-height-h1: 4rem !default;
$line-height-h2: 3.6rem !default;
$line-height-h3: 3.2rem !default;
$line-height-title1: 2.8rem !default;
$line-height-title2: 2.4rem !default;
$line-height-body1: 2.4rem !default;
$line-height-body2: 2rem !default;
$line-height-caption: 1.6rem !default;
$line-height-fineprint1: 1.6rem !default;
$line-height-fineprint2: 1.2rem !default;

@mixin typography($font-size, $font-weight, $line-height) {
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
}

@mixin display1($font-weight) {
    @include typography($font-size-display1, $font-weight, $line-height-display1);
}

@mixin display2($font-weight) {
    @include typography($font-size-display2, $font-weight, $line-height-display2);
}

@mixin display3($font-weight) {
    @include typography($font-size-display3, $font-weight, $line-height-display3);
}

@mixin h1($font-weight) {
    @include typography($font-size-h1, $font-weight, $line-height-h1);
}

@mixin h2($font-weight) {
    @include typography($font-size-h2, $font-weight, $line-height-h2);
}

@mixin h3($font-weight) {
    @include typography($font-size-h3, $font-weight, $line-height-h3);
}

@mixin title1($font-weight) {
    @include typography($font-size-title1, $font-weight, $line-height-title1);
}

@mixin title2($font-weight) {
    @include typography($font-size-title2, $font-weight, $line-height-title2);
}

@mixin body1($font-weight) {
    @include typography($font-size-body1, $font-weight, $line-height-body1);
}

@mixin body2($font-weight) {
    @include typography($font-size-body2, $font-weight, $line-height-body2);
}

@mixin caption($font-weight) {
    @include typography($font-size-caption, $font-weight, $line-height-caption);
}

@mixin fineprint1($font-weight) {
    @include typography($font-size-fineprint1, $font-weight, $line-height-fineprint1);
}

@mixin fineprint2($font-weight) {
    @include typography($font-size-fineprint2, $font-weight, $line-height-fineprint2);
}

@mixin display1UltraBold {
    @include display1($font-weight-ultra-bold);
}

@mixin display1Bold {
    @include display1($font-weight-bold);
}

@mixin display1Medium {
    @include display1($font-weight-medium);
}

@mixin display1Light {
    @include display1($font-weight-light);
}

@mixin display2UltraBold {
    @include display2($font-weight-ultra-bold);
}

@mixin display2Bold {
    @include display2($font-weight-bold);
}

@mixin display2Medium {
    @include display2($font-weight-medium);
}

@mixin display2Light {
    @include display2($font-weight-light);
}

@mixin display3UltraBold {
    @include display3($font-weight-ultra-bold);
}

@mixin display3Bold {
    @include display3($font-weight-bold);
}

@mixin display3Medium {
    @include display3($font-weight-medium);
}

@mixin display3Light {
    @include display3($font-weight-light);
}

@mixin h1UltraBold {
    @include h1($font-weight-ultra-bold);
}

@mixin h1Bold {
    @include h1($font-weight-bold);
}

@mixin h1Medium {
    @include h1($font-weight-medium);
}

@mixin h1Light {
    @include h1($font-weight-light);
}

@mixin h2UltraBold {
    @include h2($font-weight-ultra-bold);
}

@mixin h2Bold {
    @include h2($font-weight-bold);
}

@mixin h2Medium {
    @include h2($font-weight-medium);
}

@mixin h2Light {
    @include h2($font-weight-light);
}

@mixin h3UltraBold {
    @include h3($font-weight-ultra-bold);
}

@mixin h3Bold {
    @include h3($font-weight-bold);
}

@mixin h3Medium {
    @include h3($font-weight-medium);
}

@mixin h3Light {
    @include h3($font-weight-light);
}

@mixin title1Bold {
    @include title1($font-weight-bold);
}

@mixin title1Medium {
    @include title1($font-weight-medium);
}

@mixin title1Regular {
    @include title1($font-weight-regular);
}

@mixin title2Bold {
    @include title2($font-weight-bold);
}

@mixin title2Medium {
    @include title2($font-weight-medium);
}

@mixin title2Regular {
    @include title2($font-weight-regular);
}

@mixin body1Bold {
    @include body1($font-weight-bold);
}

@mixin body1Medium {
    @include body1($font-weight-medium);
}

@mixin body1Regular {
    @include body1($font-weight-regular);
}

@mixin body2Bold {
    @include body2($font-weight-bold);
}

@mixin body2Medium {
    @include body2($font-weight-medium);
}

@mixin body2Regular {
    @include body2($font-weight-regular);
}

@mixin captionBold {
    @include caption($font-weight-bold);
}

@mixin captionMedium {
    @include caption($font-weight-medium);
}

@mixin captionRegular {
    @include caption($font-weight-regular);
}

@mixin fineprint1Bold {
    @include fineprint1($font-weight-bold);
}

@mixin fineprint1Medium {
    @include fineprint1($font-weight-medium);
}

@mixin fineprint1Regular {
    @include fineprint1($font-weight-regular);
}

@mixin fineprint2Bold {
    @include fineprint2($font-weight-bold);
}

@mixin fineprint2Medium {
    @include fineprint2($font-weight-medium);
}

@mixin fineprint2Regular {
    @include fineprint2($font-weight-regular);
}

@mixin text-gradient($gradient) {
    background: $gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
