.oranum {
    /* Different variants of the primary color */
    --color-primary-1: 245, 241, 248; /* #f5f1f8 #F4F0F8 */
    --color-primary-2: 211, 194, 229; /* #d3c2e5 */
    --color-primary-35: 190, 166, 216; /* #BEA6D8 */
    --color-primary-3: 143, 119, 164; /* #8f77a4 */
    --color-primary-4: 110, 85, 133; /* #6e5585 */
    --color-primary-5: 154, 53, 192; /* #9a35c0 */
    --color-primary-51: 134, 44, 168; /* #862CA8 */
    --color-primary-6: 86, 51, 117; /* #563375 */
    --color-primary-7: 69, 41, 91; /* #45295B */
    --color-primary-8: 51, 32, 66; /* #332042 */
    --color-primary-9: 58, 47, 67; /* #3a2f43 */
    --color-primary-10: 134, 44, 168; /* #862CA8 */
    /* aliases for the most widely used */
    --color-primary: var(--color-primary-6);
    --color-primary-active: var(--color-primary-5);
    /* Different variants of secondary color */
    --color-secondary-1: 255, 202, 60; /* #ffca3c */
    --color-secondary-2: 255, 228, 0; /* #ffe400 */
    --color-secondary-3: 255, 202, 60; /* #ffca3c */
    /* alias for the most widely used */
    --color-secondary: var(--color-secondary-1);
    --color-secondary-active: var(--color-secondary-2);
    /* gradients */
    --gradient-primary: linear-gradient(
        45deg,
        #97416c 0%,
        #ff6699 52.03%,
        #ffa790 100%
    ); /* right now it's like BimBim */
    --gradient-success: linear-gradient(90deg, rgb(var(--color-success)) 0%, #aaec3a 100%);
    --gradient-gold: linear-gradient(180deg, rgb(var(--color-secondary-1)), rgb(var(--color-secondary-3)));
    --gradient-silver: linear-gradient(180deg, #d1d1d1 0%, #848a8f 100%);
    --gradient-bronze: linear-gradient(180deg, #e3a950 0%, #9e5531 100%);
    --gradient-purple: linear-gradient(45deg, rgb(var(--color-primary)) 0%, rgb(var(--color-primary-active)) 100%);
    --gradient-icon: linear-gradient(90deg, rgb(var(--color-primary-active)), rgb(var(--color-primary)));

    /* text colors */
    --color-text: var(--color-primary-9);
    --color-text-muted: var(--color-primary-3);
    /* the one used on NON white BG */
    --color-text-inversed: var(--color-white);
    --color-text-inversed-muted: var(--color-primary-2);
    /* the one used on white BG, e.g. breadcrumbs */
    --color-label: var(--color-primary);
    --color-label-muted: var(--color-primary-3);
    /* link colors */
    --color-link: var(--color-primary-active); /* #9a35c0 */
    --color-link-active: var(--color-primary-6);
    --color-link-inversed: var(--color-white);
    --color-link-inversed-active: var(--color-link-active);
    /* background colors */
    --color-background: 237, 230, 244; /* #ede6f4 */
    --color-background-container: var(--color-white);
    --color-background-widget: var(--color-primary-1);

    /* used for green "success" messages */
    --color-success: 133, 212, 0; /* #85d400 */
    /* color code used: http://www.ilpi.com/msds/ref/gifs/signalwordsdeprecated.jpg */
    /* used for red "error" messages */
    --color-danger: 214, 73, 73; /* #d64949 */
    --color-new-danger: 255, 0, 0; /* #ff0000 red */
    /* used for orange "warning" messages */
    --color-warning: 234, 146, 21; /* #ea9215 orange */
    /* used for yellow "caution" messages */
    --color-caution: 255, 204, 0; /* #ffcc00 sunshine */

    /* a palette of different colors used in colourful widgets, like pie chart */
    --color-palette-1: 145, 83, 244; /*  #9153F4 */
    --color-palette-2: 89, 195, 195; /* #59C3C3 */
    --color-palette-3: 241, 83, 134; /* #F15386 */
    --color-palette-4: 255, 202, 60; /* #FFCA3C */
    --color-palette-5: 5, 130, 202; /* #0582CA */
    --color-palette-6: 142, 208, 129; /* #8ED081 */
    --color-palette-7: 164, 118, 147; /* #A47693 */
    --color-palette-8: 76, 85, 235; /* #4C55EB */
    --color-palette-9: 218, 198, 0; /* #DAC600 */
    --color-palette-10: 223, 109, 208; /* #DF6DD0 */
    --color-palette-11: 53, 117, 114; /* #357572 */
    --color-palette-12: 114, 193, 223; /* #90C1DF */
    --color-palette-13: 89, 60, 112; /* #593C70 */

    --color-border: var(--color-primary-2);
    --border-radius: 0.4rem;
}
