@use 'global/typography';
@use 'global/colors';

.root {
    --switch-component-background: #{colors.get(purple, 200)};
    --switch-component-text-on: #{colors.get(purple, 700)};
    --switch-component-text-off: #{colors.get(purple, 500)};
    --switch-component-handle-on: #{colors.get(alert, success_600)};
    --switch-component-handle-off: #{colors.get(purple, 500)};
}

.livejasmin.root {
    --switch-component-background: #{colors.get(red, 700)};
    --switch-component-text-on: #{colors.get(neutral, 100)};
    --switch-component-text-off: #{colors.get(pink, 500)};
    --switch-component-handle-on: #{colors.get(alert, success_500)};
    --switch-component-handle-off: #{colors.get(red, 400)};
}

.switchButton {
    display: flex;
    align-items: center;
    width: 5.6rem;
    height: 2.4rem;
    padding: 0.2rem;
    @include typography.captionBold;
    text-transform: uppercase;
    background-color: var(--switch-component-background);
    color: var(--switch-component-text-on);
    border: 0;
    border-radius: 1.3rem;
    cursor: pointer;

    &:focus {
        outline: 0;
    }
}

.on {
    flex-direction: row-reverse;
    color: var(--switch-component-text-on);
    .handle {
        background-color: var(--switch-component-handle-on);
    }
}

.off {
    flex-direction: row;
    color: var(--switch-component-text-off);
    .handle {
        background-color: var(--switch-component-handle-off);
    }
}

.handle {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
}

.innerLabel {
    flex-grow: 1;
}

.disabled {
    opacity: 0.4;
    cursor: not-allowed;
}
