@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src:
        url('./fonts/roboto300.woff2') format('woff2'),
        url('./fonts/roboto300.woff') format('woff');
}
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src:
        url('./fonts/roboto400italic.woff2') format('woff2'),
        url('./fonts/roboto400italic.woff') format('woff');
}
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src:
        url('./fonts/roboto400.woff2') format('woff2'),
        url('./fonts/roboto400.woff') format('woff');
}
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src:
        url('./fonts/roboto500.woff2') format('woff2'),
        url('./fonts/roboto500.woff') format('woff');
}
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src:
        url('./fonts/roboto700.woff2') format('woff2'),
        url('./fonts/roboto700.woff') format('woff');
}
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src:
        url('./fonts/roboto900.woff2') format('woff2'),
        url('./fonts/roboto900.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'msc-icons';
    font-style: normal;
    font-weight: normal;
    src:
        url('./msc-icons/msc-icons.woff2') format('woff2'),
        url('./msc-icons/msc-icons.ttf') format('ttf'),
        url('./msc-icons/msc-icons.svg#msc-icons') format('svg');
}
