@use 'sass:map';
@use 'global/colors';
@use 'global/elevation';
@use 'global/vars';
@use 'components/Button/button';

.root {
    --bg-color: rgb(var(--color-primary-6));
    --header-color: #{colors.get(neutral, 100)};
    --body-color: rgb(var(--color-primary-14));
    --link-color: #{colors.get(yellow, 500)};
}

.oranum.root {
    --bg-color: #{colors.get(neutral, 100)};
    --header-color: rgb(var(--color-primary-6));
    --body-color: rgb(var(--color-primary-4));
    --link-color: #{colors.get(blue, 500)};
}

.cookieBanner {
    background-color: var(--bg-color);
    box-shadow: elevation.get(light_level_04);
    display: flex;
    flex-direction: row;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: vars.spacer(6);
    align-items: center;
    z-index: 12;

    @media (--mobile) {
        flex-direction: column;
    }
}

.meta {
    margin-right: 5.2rem;
    flex-grow: 1;

    @media (--mobile) {
        margin: 0.5rem 0 1.5rem 0;
    }
}

.header {
    color: var(--header-color);
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 2rem;
    margin: 0 0 vars.spacer(2) 0;
}

.body {
    color: var(--body-color);
    margin: 0;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.8rem;

    a {
        @include button.linkPrimary();
        color: var(--link-color);

        &::after {
            background-color: var(--link-color);
        }
    }
}

.accept {
    flex-shrink: 0;
    margin: 0 0.8rem 0 0;

    .button {
        width: auto;
    }

    @media (--mobile) {
        width: 100%;
        margin: 0.8rem 0 0 0;

        .button {
            width: 100%;
        }
    }
}

.reject {
    flex-shrink: 0;
    margin: 0 2.2rem 0 0;

    .button {
        width: auto;
    }

    @media (--mobile) {
        width: 100%;
        margin: 1rem 0 0 0;

        .button {
            width: 100%;
        }
    }
}

.manage {
    .button {
        white-space: nowrap;
    }

    @media (--mobile) {
        margin: 0.3rem 0 0 0;
    }
}
