@use 'global/colors';
@import './src/fonts.css';

/* get color names from: http://chir.ag/projects/name-that-color/ */
:root {
    --cornflower-lilac: #feadae;
    --dance: #7bd4c4;
    --lilac-bush: #a375d1;
    --lulu: #687bc4;
    --plum: #97416c;

    /* https://doclerholding.invisionapp.com/d/main/#/console/20876956/439433439/preview */
    --gradient-lulu: linear-gradient(45deg, #687bc4 0%, #7bd4c4 100%);
}
