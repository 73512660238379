/**
* File generated using "pnpm generate-icons"
* DO NOT MANUALLY UPDATE IT
*/

@charset "UTF-8";

.icon-achievements-outline::before {
    content: '\f101';
}

.icon-achievements-solid::before {
    content: '\f102';
}

.icon-action-list-outline::before {
    content: '\f103';
}

.icon-action-list-solid::before {
    content: '\f104';
}

.icon-ai-generated-outline::before {
    content: '\f105';
}

.icon-ai-generated-solid::before {
    content: '\f106';
}

.icon-ai-msg-reply-outline::before {
    content: '\f107';
}

.icon-ai-msg-reply-solid::before {
    content: '\f108';
}

.icon-ai-note-outline::before {
    content: '\f109';
}

.icon-ai-note-solid::before {
    content: '\f10a';
}

.icon-archive-outline::before {
    content: '\f10b';
}

.icon-archive-solid::before {
    content: '\f10c';
}

.icon-arrow-down-circular-outline::before {
    content: '\f10d';
}

.icon-arrow-down-circular-solid::before {
    content: '\f10e';
}

.icon-arrow-down-double-circular-outline::before {
    content: '\f10f';
}

.icon-arrow-down-double-circular-solid::before {
    content: '\f110';
}

.icon-arrow-down-double-outline::before {
    content: '\f111';
}

.icon-arrow-down-double-solid::before {
    content: '\f112';
}

.icon-arrow-down-outline::before {
    content: '\f113';
}

.icon-arrow-down-solid::before {
    content: '\f114';
}

.icon-arrow-down-triple-circular-outline::before {
    content: '\f115';
}

.icon-arrow-down-triple-circular-solid::before {
    content: '\f116';
}

.icon-arrow-down-triple-outline::before {
    content: '\f117';
}

.icon-arrow-down-triple-solid::before {
    content: '\f118';
}

.icon-arrow-left-circular-outline::before {
    content: '\f119';
}

.icon-arrow-left-circular-solid::before {
    content: '\f11a';
}

.icon-arrow-left-outline::before {
    content: '\f11b';
}

.icon-arrow-left-solid::before {
    content: '\f11c';
}

.icon-arrow-right-circular-outline::before {
    content: '\f11d';
}

.icon-arrow-right-circular-solid::before {
    content: '\f11e';
}

.icon-arrow-right-outline::before {
    content: '\f11f';
}

.icon-arrow-right-solid::before {
    content: '\f120';
}

.icon-arrow-up-circular-outline::before {
    content: '\f121';
}

.icon-arrow-up-circular-solid::before {
    content: '\f122';
}

.icon-arrow-up-double-circular-outline::before {
    content: '\f123';
}

.icon-arrow-up-double-circular-solid::before {
    content: '\f124';
}

.icon-arrow-up-double-outline::before {
    content: '\f125';
}

.icon-arrow-up-double-solid::before {
    content: '\f126';
}

.icon-arrow-up-outline::before {
    content: '\f127';
}

.icon-arrow-up-solid::before {
    content: '\f128';
}

.icon-arrow-up-triple-circular-outline::before {
    content: '\f129';
}

.icon-arrow-up-triple-circular-solid::before {
    content: '\f12a';
}

.icon-arrow-up-triple-outline::before {
    content: '\f12b';
}

.icon-arrow-up-triple-solid::before {
    content: '\f12c';
}

.icon-aspect-ratio-outline::before {
    content: '\f12d';
}

.icon-aspect-ratio-solid::before {
    content: '\f12e';
}

.icon-attachment-outline::before {
    content: '\f12f';
}

.icon-attachment-solid::before {
    content: '\f130';
}

.icon-battery0::before {
    content: '\f131';
}

.icon-battery100::before {
    content: '\f132';
}

.icon-battery15::before {
    content: '\f133';
}

.icon-battery45::before {
    content: '\f134';
}

.icon-battery75::before {
    content: '\f135';
}

.icon-battle-outline::before {
    content: '\f136';
}

.icon-battle-solid::before {
    content: '\f137';
}

.icon-bluetooth-off-outline::before {
    content: '\f138';
}

.icon-bluetooth-off-solid::before {
    content: '\f139';
}

.icon-bluetooth-on-outline::before {
    content: '\f13a';
}

.icon-bluetooth-on-solid::before {
    content: '\f13b';
}

.icon-blur-background-outline::before {
    content: '\f13c';
}

.icon-blur-background-solid::before {
    content: '\f13d';
}

.icon-blur-outline::before {
    content: '\f13e';
}

.icon-blur-solid::before {
    content: '\f13f';
}

.icon-bookmark-add-outline::before {
    content: '\f140';
}

.icon-bookmark-add-solid::before {
    content: '\f141';
}

.icon-bookmark-outline::before {
    content: '\f142';
}

.icon-bookmark-solid::before {
    content: '\f143';
}

.icon-brightness-outline::before {
    content: '\f144';
}

.icon-brightness-solid::before {
    content: '\f145';
}

.icon-bw-filter-outline::before {
    content: '\f146';
}

.icon-bw-filter-solid::before {
    content: '\f147';
}

.icon-cake-outline::before {
    content: '\f148';
}

.icon-cake-solid::before {
    content: '\f149';
}

.icon-calendar-outline::before {
    content: '\f14a';
}

.icon-calendar-solid::before {
    content: '\f14b';
}

.icon-camera-outline::before {
    content: '\f14c';
}

.icon-camera-settings-outline::before {
    content: '\f14d';
}

.icon-camera-settings-solid::before {
    content: '\f14e';
}

.icon-camera-solid::before {
    content: '\f14f';
}

.icon-capslock-outline::before {
    content: '\f150';
}

.icon-capslock-solid::before {
    content: '\f151';
}

.icon-caret-double::before {
    content: '\f152';
}

.icon-caret-down::before {
    content: '\f153';
}

.icon-caret-left::before {
    content: '\f154';
}

.icon-caret-right::before {
    content: '\f155';
}

.icon-caret-up::before {
    content: '\f156';
}

.icon-certified-outline::before {
    content: '\f157';
}

.icon-certified-solid::before {
    content: '\f158';
}

.icon-chart-bars-outline::before {
    content: '\f159';
}

.icon-chart-bars-solid::before {
    content: '\f15a';
}

.icon-chart-donut-outline::before {
    content: '\f15b';
}

.icon-chart-donut-solid::before {
    content: '\f15c';
}

.icon-chart-lines-outline::before {
    content: '\f15d';
}

.icon-chart-lines-solid::before {
    content: '\f15e';
}

.icon-check-circular-outline::before {
    content: '\f15f';
}

.icon-check-circular-solid::before {
    content: '\f160';
}

.icon-check-double-outline::before {
    content: '\f161';
}

.icon-check-double-solid::before {
    content: '\f162';
}

.icon-check-outline::before {
    content: '\f163';
}

.icon-check-solid::before {
    content: '\f164';
}

.icon-clock-outline::before {
    content: '\f165';
}

.icon-clock-solid::before {
    content: '\f166';
}

.icon-close-circular-outline::before {
    content: '\f167';
}

.icon-close-circular-solid::before {
    content: '\f168';
}

.icon-close-outline::before {
    content: '\f169';
}

.icon-close-solid::before {
    content: '\f16a';
}

.icon-coin-bim-bim-outline::before {
    content: '\f16b';
}

.icon-coin-bim-bim-solid::before {
    content: '\f16c';
}

.icon-coin-dollar-outline::before {
    content: '\f16d';
}

.icon-coin-dollar-solid::before {
    content: '\f16e';
}

.icon-coin-jasmin-outline::before {
    content: '\f16f';
}

.icon-coin-jasmin-solid::before {
    content: '\f170';
}

.icon-coin-tip-outline::before {
    content: '\f171';
}

.icon-coin-tip-solid::before {
    content: '\f172';
}

.icon-comments-off-outline::before {
    content: '\f173';
}

.icon-comments-off-solid::before {
    content: '\f174';
}

.icon-comments-on-outline::before {
    content: '\f175';
}

.icon-comments-on-solid::before {
    content: '\f176';
}

.icon-connection-off-outline::before {
    content: '\f177';
}

.icon-connection-off-solid::before {
    content: '\f178';
}

.icon-connection-on-outline::before {
    content: '\f179';
}

.icon-connection-on-solid::before {
    content: '\f17a';
}

.icon-content-quality-outline::before {
    content: '\f17b';
}

.icon-content-quality-solid::before {
    content: '\f17c';
}

.icon-contrast-outline::before {
    content: '\f17d';
}

.icon-contrast-solid::before {
    content: '\f17e';
}

.icon-copy-outline::before {
    content: '\f17f';
}

.icon-copy-solid::before {
    content: '\f180';
}

.icon-credit-card-outline::before {
    content: '\f181';
}

.icon-credit-card-solid::before {
    content: '\f182';
}

.icon-credits-outline::before {
    content: '\f183';
}

.icon-credits-rise-outline::before {
    content: '\f184';
}

.icon-credits-rise-solid::before {
    content: '\f185';
}

.icon-credits-solid::before {
    content: '\f186';
}

.icon-crown-outline::before {
    content: '\f187';
}

.icon-crown-solid::before {
    content: '\f188';
}

.icon-desktop-mobile-outline::before {
    content: '\f189';
}

.icon-desktop-mobile-solid::before {
    content: '\f18a';
}

.icon-desktop-outline::before {
    content: '\f18b';
}

.icon-desktop-settings-outline::before {
    content: '\f18c';
}

.icon-desktop-settings-solid::before {
    content: '\f18d';
}

.icon-desktop-solid::before {
    content: '\f18e';
}

.icon-desktop-volume-outline::before {
    content: '\f18f';
}

.icon-desktop-volume-solid::before {
    content: '\f190';
}

.icon-devil-heart-outline::before {
    content: '\f191';
}

.icon-devil-heart-solid::before {
    content: '\f192';
}

.icon-diamond-outline::before {
    content: '\f193';
}

.icon-diamond-solid::before {
    content: '\f194';
}

.icon-disabled-outline::before {
    content: '\f195';
}

.icon-disabled-solid::before {
    content: '\f196';
}

.icon-dislike-outline::before {
    content: '\f197';
}

.icon-dislike-solid::before {
    content: '\f198';
}

.icon-dollar-outline::before {
    content: '\f199';
}

.icon-dollar-solid::before {
    content: '\f19a';
}

.icon-download-outline::before {
    content: '\f19b';
}

.icon-download-solid::before {
    content: '\f19c';
}

.icon-drag-indicator-outline::before {
    content: '\f19d';
}

.icon-drag-indicator-solid::before {
    content: '\f19e';
}

.icon-edit-outline::before {
    content: '\f19f';
}

.icon-edit-solid::before {
    content: '\f1a0';
}

.icon-editor-outline::before {
    content: '\f1a1';
}

.icon-editor-solid::before {
    content: '\f1a2';
}

.icon-envelope-outline::before {
    content: '\f1a3';
}

.icon-envelope-solid::before {
    content: '\f1a4';
}

.icon-equalizer-outline::before {
    content: '\f1a5';
}

.icon-equalizer-solid::before {
    content: '\f1a6';
}

.icon-exposure-outline::before {
    content: '\f1a7';
}

.icon-exposure-solid::before {
    content: '\f1a8';
}

.icon-eye-off-circular-outline::before {
    content: '\f1a9';
}

.icon-eye-off-circular-solid::before {
    content: '\f1aa';
}

.icon-eye-off-outline::before {
    content: '\f1ab';
}

.icon-eye-off-solid::before {
    content: '\f1ac';
}

.icon-eye-on-circular-outline::before {
    content: '\f1ad';
}

.icon-eye-on-circular-solid::before {
    content: '\f1ae';
}

.icon-eye-on-outline::before {
    content: '\f1af';
}

.icon-eye-on-solid::before {
    content: '\f1b0';
}

.icon-eye-trophy-outline::before {
    content: '\f1b1';
}

.icon-eye-trophy-solid::before {
    content: '\f1b2';
}

.icon-facebook::before {
    content: '\f1b3';
}

.icon-fanclub-outline::before {
    content: '\f1b4';
}

.icon-fanclub-solid::before {
    content: '\f1b5';
}

.icon-filter-outline::before {
    content: '\f1b6';
}

.icon-filter-solid::before {
    content: '\f1b7';
}

.icon-flag-outline::before {
    content: '\f1b8';
}

.icon-flag-solid::before {
    content: '\f1b9';
}

.icon-flame-outline::before {
    content: '\f1ba';
}

.icon-flame-solid::before {
    content: '\f1bb';
}

.icon-flash-outline::before {
    content: '\f1bc';
}

.icon-flash-solid::before {
    content: '\f1bd';
}

.icon-flip-outline::before {
    content: '\f1be';
}

.icon-flip-solid::before {
    content: '\f1bf';
}

.icon-focus-outline::before {
    content: '\f1c0';
}

.icon-focus-solid::before {
    content: '\f1c1';
}

.icon-folder-add-outline::before {
    content: '\f1c2';
}

.icon-folder-add-solid::before {
    content: '\f1c3';
}

.icon-folder-outline::before {
    content: '\f1c4';
}

.icon-folder-photo-outline::before {
    content: '\f1c5';
}

.icon-folder-photo-solid::before {
    content: '\f1c6';
}

.icon-folder-solid::before {
    content: '\f1c7';
}

.icon-folder-video-outline::before {
    content: '\f1c8';
}

.icon-folder-video-solid::before {
    content: '\f1c9';
}

.icon-forward-outline::before {
    content: '\f1ca';
}

.icon-forward-solid::before {
    content: '\f1cb';
}

.icon-full-screen-close-outline::before {
    content: '\f1cc';
}

.icon-full-screen-close-solid::before {
    content: '\f1cd';
}

.icon-full-screen-outline::before {
    content: '\f1ce';
}

.icon-full-screen-solid::before {
    content: '\f1cf';
}

.icon-gender-female-outline::before {
    content: '\f1d0';
}

.icon-gender-female-solid::before {
    content: '\f1d1';
}

.icon-gender-male-outline::before {
    content: '\f1d2';
}

.icon-gender-male-solid::before {
    content: '\f1d3';
}

.icon-gender-trans-outline::before {
    content: '\f1d4';
}

.icon-gender-trans-solid::before {
    content: '\f1d5';
}

.icon-gift-outline::before {
    content: '\f1d6';
}

.icon-gift-solid::before {
    content: '\f1d7';
}

.icon-globe-outline::before {
    content: '\f1d8';
}

.icon-globe-solid::before {
    content: '\f1d9';
}

.icon-grid-large-outline::before {
    content: '\f1da';
}

.icon-grid-large-solid::before {
    content: '\f1db';
}

.icon-grid-medium-outline::before {
    content: '\f1dc';
}

.icon-grid-medium-solid::before {
    content: '\f1dd';
}

.icon-grid-small-outline::before {
    content: '\f1de';
}

.icon-grid-small-solid::before {
    content: '\f1df';
}

.icon-group-add-outline::before {
    content: '\f1e0';
}

.icon-group-add-solid::before {
    content: '\f1e1';
}

.icon-group-outline::before {
    content: '\f1e2';
}

.icon-group-solid::before {
    content: '\f1e3';
}

.icon-group-studio-outline::before {
    content: '\f1e4';
}

.icon-group-studio-solid::before {
    content: '\f1e5';
}

.icon-group-three-outline::before {
    content: '\f1e6';
}

.icon-group-three-solid::before {
    content: '\f1e7';
}

.icon-group-two-outline::before {
    content: '\f1e8';
}

.icon-group-two-solid::before {
    content: '\f1e9';
}

.icon-hashtag-circular-outline::before {
    content: '\f1ea';
}

.icon-hashtag-circular-solid::before {
    content: '\f1eb';
}

.icon-hashtag-outline::before {
    content: '\f1ec';
}

.icon-hashtag-solid::before {
    content: '\f1ed';
}

.icon-hd-outline::before {
    content: '\f1ee';
}

.icon-hd-solid::before {
    content: '\f1ef';
}

.icon-headphones-outline::before {
    content: '\f1f0';
}

.icon-headphones-solid::before {
    content: '\f1f1';
}

.icon-heart-outline::before {
    content: '\f1f2';
}

.icon-heart-solid::before {
    content: '\f1f3';
}

.icon-heartbeat-outline::before {
    content: '\f1f4';
}

.icon-heartbeat-solid::before {
    content: '\f1f5';
}

.icon-help-circular-outline::before {
    content: '\f1f6';
}

.icon-help-circular-solid::before {
    content: '\f1f7';
}

.icon-help-outline::before {
    content: '\f1f8';
}

.icon-help-solid::before {
    content: '\f1f9';
}

.icon-highlights-outline::before {
    content: '\f1fa';
}

.icon-highlights-solid::before {
    content: '\f1fb';
}

.icon-history-outline::before {
    content: '\f1fc';
}

.icon-history-solid::before {
    content: '\f1fd';
}

.icon-home-outline::before {
    content: '\f1fe';
}

.icon-home-solid::before {
    content: '\f1ff';
}

.icon-hourglass-outline::before {
    content: '\f200';
}

.icon-hourglass-solid::before {
    content: '\f201';
}

.icon-id-card-outline::before {
    content: '\f202';
}

.icon-id-card-solid::before {
    content: '\f203';
}

.icon-indoor-outdoor-outline::before {
    content: '\f204';
}

.icon-indoor-outdoor-solid::before {
    content: '\f205';
}

.icon-indoor-solid::before {
    content: '\f206';
}

.icon-infinit-outline::before {
    content: '\f207';
}

.icon-infinit-solid::before {
    content: '\f208';
}

.icon-info-circular-outline::before {
    content: '\f209';
}

.icon-info-circular-solid::before {
    content: '\f20a';
}

.icon-info-outline::before {
    content: '\f20b';
}

.icon-info-solid::before {
    content: '\f20c';
}

.icon-instagram::before {
    content: '\f20d';
}

.icon-interactive-toy-outline::before {
    content: '\f20e';
}

.icon-interactive-toy-solid::before {
    content: '\f20f';
}

.icon-interactive-toy-vibration-outline::before {
    content: '\f210';
}

.icon-interactive-toy-vibration-solid::before {
    content: '\f211';
}

.icon-keyboard-outline::before {
    content: '\f212';
}

.icon-keyboard-solid::before {
    content: '\f213';
}

.icon-kick-outline::before {
    content: '\f214';
}

.icon-kick-solid::before {
    content: '\f215';
}

.icon-light-bulb-outline::before {
    content: '\f216';
}

.icon-light-bulb-solid::before {
    content: '\f217';
}

.icon-light-bulb-sparkle-outline::before {
    content: '\f218';
}

.icon-light-bulb-sparkle-solid::before {
    content: '\f219';
}

.icon-like-outline::before {
    content: '\f21a';
}

.icon-like-solid::before {
    content: '\f21b';
}

.icon-lips-outline::before {
    content: '\f21c';
}

.icon-lips-solid::before {
    content: '\f21d';
}

.icon-live-outline::before {
    content: '\f21e';
}

.icon-live-solid::before {
    content: '\f21f';
}

.icon-location-outline::before {
    content: '\f220';
}

.icon-location-solid::before {
    content: '\f221';
}

.icon-locket-close-outline::before {
    content: '\f222';
}

.icon-locket-close-solid::before {
    content: '\f223';
}

.icon-locket-heart-close-outline::before {
    content: '\f224';
}

.icon-locket-heart-close-solid::before {
    content: '\f225';
}

.icon-locket-heart-open-outline::before {
    content: '\f226';
}

.icon-locket-heart-open-solid::before {
    content: '\f227';
}

.icon-locket-money-close-outline::before {
    content: '\f228';
}

.icon-locket-money-close-solid::before {
    content: '\f229';
}

.icon-locket-open-outline::before {
    content: '\f22a';
}

.icon-locket-open-solid::before {
    content: '\f22b';
}

.icon-login-outline::before {
    content: '\f22c';
}

.icon-login-solid::before {
    content: '\f22d';
}

.icon-logos-loyalfans::before {
    content: '\f22e';
}

.icon-logout-outline::before {
    content: '\f22f';
}

.icon-logout-solid::before {
    content: '\f230';
}

.icon-magazine-outline::before {
    content: '\f231';
}

.icon-magazine-solid::before {
    content: '\f232';
}

.icon-mask-outline::before {
    content: '\f233';
}

.icon-mask-solid::before {
    content: '\f234';
}

.icon-mass-message-outline::before {
    content: '\f235';
}

.icon-mass-message-solid::before {
    content: '\f236';
}

.icon-medal-star-outline::before {
    content: '\f237';
}

.icon-medal-star-solid::before {
    content: '\f238';
}

.icon-megaphone-outline::before {
    content: '\f239';
}

.icon-megaphone-solid::before {
    content: '\f23a';
}

.icon-menu-hamburger-outline::before {
    content: '\f23b';
}

.icon-menu-hamburger-solid::before {
    content: '\f23c';
}

.icon-menu-more-horizontal-outline::before {
    content: '\f23d';
}

.icon-menu-more-horizontal-solid::before {
    content: '\f23e';
}

.icon-menu-more-vertical-outline::before {
    content: '\f23f';
}

.icon-menu-more-vertical-solid::before {
    content: '\f240';
}

.icon-microphone-off-outline::before {
    content: '\f241';
}

.icon-microphone-off-solid::before {
    content: '\f242';
}

.icon-microphone-on-outline::before {
    content: '\f243';
}

.icon-microphone-on-solid::before {
    content: '\f244';
}

.icon-minus-circular-outline::before {
    content: '\f245';
}

.icon-minus-circular-solid::before {
    content: '\f246';
}

.icon-minus-outline::before {
    content: '\f247';
}

.icon-minus-solid::before {
    content: '\f248';
}

.icon-mobile-outline::before {
    content: '\f249';
}

.icon-mobile-rotation-outline::before {
    content: '\f24a';
}

.icon-mobile-rotation-solid::before {
    content: '\f24b';
}

.icon-mobile-solid::before {
    content: '\f24c';
}

.icon-moon-outline::before {
    content: '\f24d';
}

.icon-moon-solid::before {
    content: '\f24e';
}

.icon-movie-outline::before {
    content: '\f24f';
}

.icon-movie-solid::before {
    content: '\f250';
}

.icon-music-note-outline::before {
    content: '\f251';
}

.icon-music-note-solid::before {
    content: '\f252';
}

.icon-my-content-outline::before {
    content: '\f253';
}

.icon-my-content-solid::before {
    content: '\f254';
}

.icon-next-outline::before {
    content: '\f255';
}

.icon-next-solid::before {
    content: '\f256';
}

.icon-next-song-outline::before {
    content: '\f257';
}

.icon-next-song-solid::before {
    content: '\f258';
}

.icon-note-add-outline::before {
    content: '\f259';
}

.icon-note-add-solid::before {
    content: '\f25a';
}

.icon-note-outline::before {
    content: '\f25b';
}

.icon-note-solid::before {
    content: '\f25c';
}

.icon-notifications-off-outline::before {
    content: '\f25d';
}

.icon-notifications-off-solid::before {
    content: '\f25e';
}

.icon-notifications-on-outline::before {
    content: '\f25f';
}

.icon-notifications-on-solid::before {
    content: '\f260';
}

.icon-outdoor-solid::before {
    content: '\f261';
}

.icon-paste-outline::before {
    content: '\f262';
}

.icon-paste-solid::before {
    content: '\f263';
}

.icon-pause-circular-outline::before {
    content: '\f264';
}

.icon-pause-circular-solid::before {
    content: '\f265';
}

.icon-pause-outline::before {
    content: '\f266';
}

.icon-pause-solid::before {
    content: '\f267';
}

.icon-payout-outline::before {
    content: '\f268';
}

.icon-payout-solid::before {
    content: '\f269';
}

.icon-percentage-outline::before {
    content: '\f26a';
}

.icon-percentage-solid::before {
    content: '\f26b';
}

.icon-performance-outline::before {
    content: '\f26c';
}

.icon-performance-solid::before {
    content: '\f26d';
}

.icon-phone-down-outline::before {
    content: '\f26e';
}

.icon-phone-down-solid::before {
    content: '\f26f';
}

.icon-phone-outline::before {
    content: '\f270';
}

.icon-phone-solid::before {
    content: '\f271';
}

.icon-photo-add-outline::before {
    content: '\f272';
}

.icon-photo-add-solid::before {
    content: '\f273';
}

.icon-photo-filter-outline::before {
    content: '\f274';
}

.icon-photo-filter-solid::before {
    content: '\f275';
}

.icon-photo-gallery-outline::before {
    content: '\f276';
}

.icon-photo-gallery-solid::before {
    content: '\f277';
}

.icon-photo-off-outline::before {
    content: '\f278';
}

.icon-photo-off-solid::before {
    content: '\f279';
}

.icon-photo-single-outline::before {
    content: '\f27a';
}

.icon-photo-single-solid::before {
    content: '\f27b';
}

.icon-pin-outline::before {
    content: '\f27c';
}

.icon-pin-solid::before {
    content: '\f27d';
}

.icon-play-circular-outline::before {
    content: '\f27e';
}

.icon-play-circular-solid::before {
    content: '\f27f';
}

.icon-play-outline::before {
    content: '\f280';
}

.icon-play-solid::before {
    content: '\f281';
}

.icon-plus-circular-outline::before {
    content: '\f282';
}

.icon-plus-circular-solid::before {
    content: '\f283';
}

.icon-plus-outline::before {
    content: '\f284';
}

.icon-plus-solid::before {
    content: '\f285';
}

.icon-previous-outline::before {
    content: '\f286';
}

.icon-previous-solid::before {
    content: '\f287';
}

.icon-previous-song-outline::before {
    content: '\f288';
}

.icon-previous-song-solid::before {
    content: '\f289';
}

.icon-private-invitation-cancel-outline::before {
    content: '\f28a';
}

.icon-private-invitation-cancel-solid::before {
    content: '\f28b';
}

.icon-private-invitation-outline::before {
    content: '\f28c';
}

.icon-private-invitation-progress-outline::before {
    content: '\f28d';
}

.icon-private-invitation-progress-solid::before {
    content: '\f28e';
}

.icon-private-invitation-progress-star-outline::before {
    content: '\f28f';
}

.icon-private-invitation-progress-star-solid::before {
    content: '\f290';
}

.icon-private-invitation-solid::before {
    content: '\f291';
}

.icon-private-invitation-star-outline::before {
    content: '\f292';
}

.icon-private-invitation-star-solid::before {
    content: '\f293';
}

.icon-private-invitation-success-outline::before {
    content: '\f294';
}

.icon-private-invitation-success-solid::before {
    content: '\f295';
}

.icon-pro-outline::before {
    content: '\f296';
}

.icon-pro-solid::before {
    content: '\f297';
}

.icon-profile-add-outline::before {
    content: '\f298';
}

.icon-profile-add-solid::before {
    content: '\f299';
}

.icon-profile-details-outline::before {
    content: '\f29a';
}

.icon-profile-details-solid::before {
    content: '\f29b';
}

.icon-profile-frame-outline::before {
    content: '\f29c';
}

.icon-profile-frame-solid::before {
    content: '\f29d';
}

.icon-profile-outline::before {
    content: '\f29e';
}

.icon-profile-solid::before {
    content: '\f29f';
}

.icon-profile-studio-outline::before {
    content: '\f2a0';
}

.icon-profile-studio-solid::before {
    content: '\f2a1';
}

.icon-promotion-delete-outline::before {
    content: '\f2a2';
}

.icon-promotion-delete-solid::before {
    content: '\f2a3';
}

.icon-promotion-outline::before {
    content: '\f2a4';
}

.icon-promotion-solid::before {
    content: '\f2a5';
}

.icon-public-location-outline::before {
    content: '\f2a6';
}

.icon-public-location-solid::before {
    content: '\f2a7';
}

.icon-publish-outline::before {
    content: '\f2a8';
}

.icon-publish-solid::before {
    content: '\f2a9';
}

.icon-puzzle-outline::before {
    content: '\f2aa';
}

.icon-puzzle-solid::before {
    content: '\f2ab';
}

.icon-quick-reply-outline::before {
    content: '\f2ac';
}

.icon-quick-reply-solid::before {
    content: '\f2ad';
}

.icon-quote-outline::before {
    content: '\f2ae';
}

.icon-quote-solid::before {
    content: '\f2af';
}

.icon-record-circular-outline::before {
    content: '\f2b0';
}

.icon-record-circular-solid::before {
    content: '\f2b1';
}

.icon-referral-outline::before {
    content: '\f2b2';
}

.icon-referral-solid::before {
    content: '\f2b3';
}

.icon-reload-outline::before {
    content: '\f2b4';
}

.icon-reload-solid::before {
    content: '\f2b5';
}

.icon-repeat-outline::before {
    content: '\f2b6';
}

.icon-repeat-solid::before {
    content: '\f2b7';
}

.icon-reply-outline::before {
    content: '\f2b8';
}

.icon-reply-solid::before {
    content: '\f2b9';
}

.icon-robot-outline::before {
    content: '\f2ba';
}

.icon-robot-solid::before {
    content: '\f2bb';
}

.icon-rocket-outline::before {
    content: '\f2bc';
}

.icon-rocket-solid::before {
    content: '\f2bd';
}

.icon-room-topic-outline::before {
    content: '\f2be';
}

.icon-room-topic-solid::before {
    content: '\f2bf';
}

.icon-rotate-outline::before {
    content: '\f2c0';
}

.icon-rotate-solid::before {
    content: '\f2c1';
}

.icon-rotation360-outline::before {
    content: '\f2c2';
}

.icon-rotation360-solid::before {
    content: '\f2c3';
}

.icon-saturation-outline::before {
    content: '\f2c4';
}

.icon-saturation-solid::before {
    content: '\f2c5';
}

.icon-scissors-outline::before {
    content: '\f2c6';
}

.icon-scissors-solid::before {
    content: '\f2c7';
}

.icon-screen-full-outline::before {
    content: '\f2c8';
}

.icon-screen-full-solid::before {
    content: '\f2c9';
}

.icon-screen-large-outline::before {
    content: '\f2ca';
}

.icon-screen-large-solid::before {
    content: '\f2cb';
}

.icon-screen-standard-outline::before {
    content: '\f2cc';
}

.icon-screen-standard-solid::before {
    content: '\f2cd';
}

.icon-search-circular-outline::before {
    content: '\f2ce';
}

.icon-search-circular-solid::before {
    content: '\f2cf';
}

.icon-search-outline::before {
    content: '\f2d0';
}

.icon-search-solid::before {
    content: '\f2d1';
}

.icon-send-outline::before {
    content: '\f2d2';
}

.icon-send-solid::before {
    content: '\f2d3';
}

.icon-settings-outline::before {
    content: '\f2d4';
}

.icon-settings-solid::before {
    content: '\f2d5';
}

.icon-share-outline::before {
    content: '\f2d6';
}

.icon-share-solid::before {
    content: '\f2d7';
}

.icon-sharpness-outline::before {
    content: '\f2d8';
}

.icon-sharpness-solid::before {
    content: '\f2d9';
}

.icon-shield-outline::before {
    content: '\f2da';
}

.icon-shield-solid::before {
    content: '\f2db';
}

.icon-shopping-bag-heart-outline::before {
    content: '\f2dc';
}

.icon-shopping-bag-heart-solid::before {
    content: '\f2dd';
}

.icon-shopping-bag-outline::before {
    content: '\f2de';
}

.icon-shopping-bag-solid::before {
    content: '\f2df';
}

.icon-shuffle-outline::before {
    content: '\f2e0';
}

.icon-shuffle-solid::before {
    content: '\f2e1';
}

.icon-shutter-timer-outline::before {
    content: '\f2e2';
}

.icon-shutter-timer-solid::before {
    content: '\f2e3';
}

.icon-smiley-happy-outline::before {
    content: '\f2e4';
}

.icon-smiley-happy-solid::before {
    content: '\f2e5';
}

.icon-smiley-neutral-outline::before {
    content: '\f2e6';
}

.icon-smiley-neutral-solid::before {
    content: '\f2e7';
}

.icon-smiley-sad-outline::before {
    content: '\f2e8';
}

.icon-smiley-sad-solid::before {
    content: '\f2e9';
}

.icon-sort-outline::before {
    content: '\f2ea';
}

.icon-sort-solid::before {
    content: '\f2eb';
}

.icon-speech-text-outline::before {
    content: '\f2ec';
}

.icon-speech-text-solid::before {
    content: '\f2ed';
}

.icon-speed-outline::before {
    content: '\f2ee';
}

.icon-speed-solid::before {
    content: '\f2ef';
}

.icon-speed-test-download-outline::before {
    content: '\f2f0';
}

.icon-speed-test-download-solid::before {
    content: '\f2f1';
}

.icon-speed-test-jitter-outline::before {
    content: '\f2f2';
}

.icon-speed-test-jitter-solid::before {
    content: '\f2f3';
}

.icon-speed-test-ping-outline::before {
    content: '\f2f4';
}

.icon-speed-test-ping-solid::before {
    content: '\f2f5';
}

.icon-speed-test-upload-outline::before {
    content: '\f2f6';
}

.icon-speed-test-upload-solid::before {
    content: '\f2f7';
}

.icon-squeeze-outline::before {
    content: '\f2f8';
}

.icon-squeeze-solid::before {
    content: '\f2f9';
}

.icon-star-circular-outline::before {
    content: '\f2fa';
}

.icon-star-circular-solid::before {
    content: '\f2fb';
}

.icon-star-double-outline::before {
    content: '\f2fc';
}

.icon-star-double-solid::before {
    content: '\f2fd';
}

.icon-star-outline::before {
    content: '\f2fe';
}

.icon-star-solid::before {
    content: '\f2ff';
}

.icon-star-triple-outline::before {
    content: '\f300';
}

.icon-star-triple-solid::before {
    content: '\f301';
}

.icon-stop-circular-outline::before {
    content: '\f302';
}

.icon-stop-circular-solid::before {
    content: '\f303';
}

.icon-stop-outline::before {
    content: '\f304';
}

.icon-stop-solid::before {
    content: '\f305';
}

.icon-stories-outline::before {
    content: '\f306';
}

.icon-stories-solid::before {
    content: '\f307';
}

.icon-stream-expand-outline::before {
    content: '\f308';
}

.icon-stream-expand-portrait-outline::before {
    content: '\f309';
}

.icon-stream-expand-portrait-solid::before {
    content: '\f30a';
}

.icon-stream-expand-solid::before {
    content: '\f30b';
}

.icon-stream-minimize-outline::before {
    content: '\f30c';
}

.icon-stream-minimize-portrait-outline::before {
    content: '\f30d';
}

.icon-stream-minimize-portrait-solid::before {
    content: '\f30e';
}

.icon-stream-minimize-solid::before {
    content: '\f30f';
}

.icon-stream-outline::before {
    content: '\f310';
}

.icon-stream-solid::before {
    content: '\f311';
}

.icon-suction-outline::before {
    content: '\f312';
}

.icon-suction-solid::before {
    content: '\f313';
}

.icon-sun-outline::before {
    content: '\f314';
}

.icon-sun-solid::before {
    content: '\f315';
}

.icon-support-outline::before {
    content: '\f316';
}

.icon-support-solid::before {
    content: '\f317';
}

.icon-swap-outline::before {
    content: '\f318';
}

.icon-swap-solid::before {
    content: '\f319';
}

.icon-table-chart-outline::before {
    content: '\f31a';
}

.icon-table-chart-solid::before {
    content: '\f31b';
}

.icon-tag-outline::before {
    content: '\f31c';
}

.icon-tag-solid::before {
    content: '\f31d';
}

.icon-telegram::before {
    content: '\f31e';
}

.icon-text-size-outline::before {
    content: '\f31f';
}

.icon-text-size-solid::before {
    content: '\f320';
}

.icon-thermometer-outline::before {
    content: '\f321';
}

.icon-thermometer-solid::before {
    content: '\f322';
}

.icon-thrusting-outline::before {
    content: '\f323';
}

.icon-thrusting-solid::before {
    content: '\f324';
}

.icon-tik-tok::before {
    content: '\f325';
}

.icon-tonality-outline::before {
    content: '\f326';
}

.icon-tonality-solid::before {
    content: '\f327';
}

.icon-toy2-toy-off-outline::before {
    content: '\f328';
}

.icon-toy2-toy-off-solid::before {
    content: '\f329';
}

.icon-toy2-toy-on-outline::before {
    content: '\f32a';
}

.icon-toy2-toy-on-solid::before {
    content: '\f32b';
}

.icon-translation-outline::before {
    content: '\f32c';
}

.icon-translation-solid::before {
    content: '\f32d';
}

.icon-trash-outline::before {
    content: '\f32e';
}

.icon-trash-restore-outline::before {
    content: '\f32f';
}

.icon-trash-restore-solid::before {
    content: '\f330';
}

.icon-trash-solid::before {
    content: '\f331';
}

.icon-trending-down-outline::before {
    content: '\f332';
}

.icon-trending-down-solid::before {
    content: '\f333';
}

.icon-trending-up-outline::before {
    content: '\f334';
}

.icon-trending-up-solid::before {
    content: '\f335';
}

.icon-trophy-outline::before {
    content: '\f336';
}

.icon-trophy-solid::before {
    content: '\f337';
}

.icon-tutorial-outline::before {
    content: '\f338';
}

.icon-tutorial-solid::before {
    content: '\f339';
}

.icon-twitter::before {
    content: '\f33a';
}

.icon-unarchive-outline::before {
    content: '\f33b';
}

.icon-unarchive-solid::before {
    content: '\f33c';
}

.icon-upload-outline::before {
    content: '\f33d';
}

.icon-upload-solid::before {
    content: '\f33e';
}

.icon-vibration-outline::before {
    content: '\f33f';
}

.icon-vibration-solid::before {
    content: '\f340';
}

.icon-video-call-off-outline::before {
    content: '\f341';
}

.icon-video-call-off-solid::before {
    content: '\f342';
}

.icon-video-call-on-outline::before {
    content: '\f343';
}

.icon-video-call-on-solid::before {
    content: '\f344';
}

.icon-video-camera-outline::before {
    content: '\f345';
}

.icon-video-camera-solid::before {
    content: '\f346';
}

.icon-video-landscape-add-outline::before {
    content: '\f347';
}

.icon-video-landscape-add-solid::before {
    content: '\f348';
}

.icon-video-landscape-outline::before {
    content: '\f349';
}

.icon-video-landscape-solid::before {
    content: '\f34a';
}

.icon-video-portrait-add-outline::before {
    content: '\f34b';
}

.icon-video-portrait-add-solid::before {
    content: '\f34c';
}

.icon-video-portrait-outline::before {
    content: '\f34d';
}

.icon-video-portrait-solid::before {
    content: '\f34e';
}

.icon-vip-outline::before {
    content: '\f34f';
}

.icon-vip-solid::before {
    content: '\f350';
}

.icon-volume-high-outline::before {
    content: '\f351';
}

.icon-volume-high-solid::before {
    content: '\f352';
}

.icon-volume-low-outline::before {
    content: '\f353';
}

.icon-volume-low-solid::before {
    content: '\f354';
}

.icon-volume-mute-outline::before {
    content: '\f355';
}

.icon-volume-mute-solid::before {
    content: '\f356';
}

.icon-wallet-outline::before {
    content: '\f357';
}

.icon-wallet-solid::before {
    content: '\f358';
}

.icon-warning-circular-outline::before {
    content: '\f359';
}

.icon-warning-circular-solid::before {
    content: '\f35a';
}

.icon-warning-outline::before {
    content: '\f35b';
}

.icon-warning-solid::before {
    content: '\f35c';
}

.icon-webcam-outline::before {
    content: '\f35d';
}

.icon-webcam-solid::before {
    content: '\f35e';
}

.icon-website-earnings-outline::before {
    content: '\f35f';
}

.icon-website-earnings-solid::before {
    content: '\f360';
}

.icon-whatsapp::before {
    content: '\f361';
}

.icon-white-balance-outline::before {
    content: '\f362';
}

.icon-white-balance-solid::before {
    content: '\f363';
}

.icon-widget-add-outline::before {
    content: '\f364';
}

.icon-widget-add-solid::before {
    content: '\f365';
}

.icon-widget-outline::before {
    content: '\f366';
}

.icon-widget-solid::before {
    content: '\f367';
}

.icon-wrench-outline::before {
    content: '\f368';
}

.icon-wrench-solid::before {
    content: '\f369';
}

.icon-x::before {
    content: '\f36a';
}

.icon-youtube::before {
    content: '\f36b';
}

.icon-zoom-in-outline::before {
    content: '\f36c';
}

.icon-zoom-in-solid::before {
    content: '\f36d';
}

.icon-zoom-out-outline::before {
    content: '\f36e';
}

.icon-zoom-out-solid::before {
    content: '\f36f';
}

.icon-zoom-outline::before {
    content: '\f370';
}

.icon-zoom-solid::before {
    content: '\f371';
}
