@use 'global/colors';
@use 'global/icons';

:global {
    * {
        box-sizing: border-box;
    }
    html {
        font-size: 62.5%;
        font-family: Roboto, Arial, sans-serif;
        --scroll-behavior: smooth;
        scroll-behavior: smooth;
    }

    body {
        margin: 0;
        background-color: rgb(var(--color-background));
    }

    a {
        text-decoration: none;
    }

    .browserFailMessage {
        display: none;
        z-index: 9999;
        position: fixed;
        color: #{colors.get(neutral, 100)};
        background: black;
        width: 100%;
        padding: 1rem 5rem 1rem 1rem;
        font-size: 1.2rem;
        text-align: center;

        span {
            position: relative;
            top: -0.5rem;
        }

        i.icon-alert {
            margin-right: 0.8rem;
            color: #{colors.get(yellow, 500)};
            font-size: 2.4rem;
            position: relative;
            top: 0.7rem;
        }

        i.icon-close {
            position: absolute;
            color: #{colors.get(neutral, 100)};
            font-size: 2.4rem;
            right: 2rem;
            top: calc(50% - 1.2rem);
            cursor: pointer;

            &:hover::before {
                content: icons.get('close-solid');
            }
        }
    }

    ul {
        padding-inline-start: 0;
    }

    @media (orientation: landscape) {
        body {
            padding-left: env(safe-area-inset-left);
            padding-right: env(safe-area-inset-right);
        }
    }
}
