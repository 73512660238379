@use 'global/colors';
@use 'global/vars';

// Please note this mixin is used for React-generated buttons (./Button.module.scss), legacy buttons generated on the
// back end (component/App/_buttons.scss), and <a> tags that get templated into translation strings.

button,
.button,
.a.button,
a {
    --primary: #{colors.get(neutral, 800)};
    --primary-bg: #{colors.get-gradient(yellow_500_400)};
    --primary-bg-hover: #{colors.get(yellow, 400)};
    --primary-bg-active: #{colors.get(yellow, 400, 0.4)};
    --secondary1: #{colors.get(neutral, 100)};
    --secondary1-hover: #{colors.get(neutral, 700)};
    --secondary1-bg: transparent;
    --secondary1-bg-hover: #{colors.get(neutral, 100)};
    --secondary1-bg-active: #{colors.get(neutral, 100, 0.4)};
    --secondary1-border: #{colors.get(neutral, 100, 0.6)};
    --secondary1-border-hover: #{colors.get(neutral, 100)};
    --secondary2: #{colors.get(neutral, 700)};
    --secondary2-hover: #{colors.get(neutral, 100)};
    --secondary2-bg: #{colors.get(neutral, 100)};
    --secondary2-bg-hover: #{colors.get(neutral, 700)};
    --secondary2-bg-active: #{colors.get(neutral, 700, 0.4)};
    --secondary2-border: #{colors.get(neutral, 700, 0.6)};
    --secondary2-border-hover: #{colors.get(neutral, 700)};
    --text-primary: #{colors.get(yellow, 500)};
    --text-primary-hover: #{colors.get(neutral, 100)};
    --text-secondary1: #{colors.get(blue, 500)};
    --text-secondary1-hover: #{colors.get(neutral, 700)};
    --text-secondary2: #{colors.get(blue, 400)};
    --text-secondary2-hover: #{colors.get(neutral, 100)};
    --link-primary: #{colors.get(yellow, 500)};
    --link-secondary1: #{colors.get(blue, 500)};
    --link-secondary2: #{colors.get(blue, 400)};
}

.oranum-theme button,
.oranum-theme .button,
.oranum-theme a.button,
.oranum.button,
.oranum-theme a {
    --secondary2: #{colors.get(purple, 700)};
    --secondary2-hover: #{colors.get(neutral, 100)};
    --secondary2-bg: transparent;
    --secondary2-bg-hover: #{colors.get(purple, 700)};
    --secondary2-bg-active: #{colors.get(purple, 700, 0.4)};
    --secondary2-border: #{colors.get(purple, 700, 0.6)};
    --secondary2-border-hover: #{colors.get(purple, 700)};
    --text-primary: #{colors.get(blue, 500)};
    --text-primary-hover: #{colors.get(purple, 700)};
    --text-secondary1: #{colors.get(blue, 400)};
    --text-secondary1-hover: #{colors.get(neutral, 100)};
    --text-secondary2: #{colors.get(blue, 400)};
    --text-secondary2-hover: #{colors.get(neutral, 100)};
    --link-primary: #{colors.get(blue, 500)};
    --link-secondary1: #{colors.get(blue, 400)};
    --link-secondary2: #{colors.get(blue, 400)};
}

@mixin base() {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-width: 6.4rem;
    font-family: Roboto, Arial, sans-serif;
    font-weight: 500;
    text-align: center;
    user-select: none;
    cursor: pointer;
    text-decoration: none;
    border-width: 0.1rem;
    border-style: solid;
    border-radius: 0.8rem;
    outline: none;
    -webkit-tap-highlight-color: #{colors.get(neutral, 900, 0)};

    &.disabled,
    &[disabled] {
        opacity: 0.4;
        cursor: not-allowed;
    }

    & > i {
        margin-right: 0.4rem;
    }
}

@mixin textBase() {
    position: relative;
    min-width: 0;
    font-family: Roboto, Arial, sans-serif;
    font-weight: bold;
    background: none;
    box-shadow: none;
    cursor: pointer;

    &.button {
        padding: 0;
    }
}

@mixin minimal() {
    min-width: 0;
    padding: 0;
    background: transparent;
    border: none;
    box-shadow: none;

    &:not(&.disabled, &[disabled]) {
        cursor: pointer;
    }
}

@mixin primary() {
    @include base();
    color: var(--primary);
    background: var(--primary-bg);
    border-color: transparent;

    &:not(&.disabled, &[disabled], &.textBase) {
        &:hover {
            background: var(--primary-bg-hover);
        }

        &:active {
            box-shadow: 0 0 0 0.4rem var(--primary-bg-active);
        }
    }
}

@mixin secondary1() {
    @include base();
    color: var(--secondary1);
    background: var(--secondary1-bg);
    border-color: var(--secondary1-border);

    &:not(&.disabled, &[disabled], &.textBase) {
        &:hover {
            color: var(--secondary1-hover);
            background: var(--secondary1-bg-hover);
            border-color: var(--secondary1-border-hover);
        }

        &:active {
            box-shadow: 0 0 0 0.4rem var(--secondary1-bg-active);
        }
    }
}

@mixin secondary2() {
    @include base();
    color: var(--secondary2);
    background: var(--secondary2-bg);
    border-color: var(--secondary2-border);

    &:not(&.disabled, &[disabled], &.textBase) {
        &:hover {
            color: var(--secondary2-hover);
            background: var(--secondary2-bg-hover);
            border-color: var(--secondary2-border-hover);
        }

        &:active {
            box-shadow: 0 0 0 0.4rem var(--secondary2-bg-active);
        }
    }
}

@mixin textPrimary() {
    @include textBase;
    color: var(--text-primary);

    &:not(&.disabled, &[disabled]) {
        &:hover {
            color: var(--text-primary-hover);
        }
    }
}

@mixin textSecondary1() {
    @include textBase;
    color: var(--text-secondary1);

    &:not(&.disabled, &[disabled]) {
        &:hover {
            color: var(--text-secondary1-hover);
        }
    }
}

@mixin textSecondary2() {
    @include textBase;
    color: var(--text-secondary2);

    &:not(&.disabled, &[disabled]) {
        &:hover {
            color: var(--text-secondary2-hover);
        }
    }
}

@mixin linkPrimary() {
    @include textBase;
    @include linkHoverEffect(var(--link-primary));
    color: var(--link-primary);
}

@mixin linkSecondary1() {
    @include textBase;
    @include linkHoverEffect(var(--link-secondary1));
    color: var(--link-secondary1);
}

@mixin linkSecondary2() {
    @include textBase;
    @include linkHoverEffect(var(--link-secondary2));
    color: var(--link-secondary2);
}

@mixin linkHoverEffect($color) {
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        transform: perspective(0.1rem) scaleX(0);
        height: 0.1rem;
        bottom: 0;
        left: 0;
        background-color: $color;
        transform-origin: bottom left;
        transition: transform 0.3s ease-out;
    }

    &:not(&.disabled, &[disabled]) {
        &:hover::after {
            transform: perspective(0.1rem) scaleX(1);
            transform-origin: bottom left;
        }
    }
}

@mixin xs() {
    padding: 0.6rem 0.8rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
}

@mixin s() {
    padding: 0.8rem 1.6rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
}

@mixin m() {
    padding: 1rem 2.4rem;
    font-size: 1.4rem;
    line-height: 2rem;
}

@mixin l() {
    padding: 1rem 3.2rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
}

@mixin xl() {
    padding: 1.2rem 4rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
}
