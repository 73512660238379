@use 'global/colors';

.livejasmin.root {
    --success-icon-color: #{colors.get(alert, success_500)};
    --warning-icon-color: #{colors.get(yellow, 500)};
}

.root {
    --toast-bg: #{colors.get(neutral, 800)};
    --toast-text: #{colors.get(neutral, 100)};
    --toast-close: #{colors.get(neutral, 500)};
    --success-icon-color: #{colors.get(alert, success_500)};
    --warning-icon-color: #{colors.get(alert, progress_500)};
    --error-icon-color: #{colors.get(alert, danger_500)};
}

.toastContainer {
    position: fixed;
    max-width: 125rem;
    width: auto;
    padding: 0 2.2rem 0 2.2rem;
    z-index: 1026;
    right: 50%;
    display: flex;
    justify-content: right;
    margin-right: -62rem;
    animation: fade-in 300ms;
    transition: top 150ms ease;

    @media (--content-squeeze-threshold) {
        right: 0;
        margin-right: 0;
    }

    &.oranum {
        top: 7.2rem;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.toast {
    margin-right: 1.6rem;
    min-width: 34.3rem;
    max-width: 54.4rem;
    min-height: 6rem;

    button {
        background: none;
        border: none;
    }
}

.toastContent {
    text-align: left;
    font-size: 1.4rem;
    line-height: 1.6;
    color: var(--toast-text);
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 1;

    i {
        flex-shrink: 0;
        margin-right: 0.8rem;
        padding: 0.3rem;
    }

    .message {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .messageList {
        padding-left: 1rem;

        ul {
            list-style: disc;
        }
    }
}

.closeBtn {
    justify-self: end;
    margin-left: 3.2rem;
    padding: 0;
    cursor: pointer;

    i {
        color: var(--toast-close);
        display: inline-flex;
        padding: 0.3rem;
    }
}

.innerWrapper {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 0.3rem;
    background: var(--toast-bg);
    min-height: inherit;
    width: 100%;
    padding: 1.6rem;
    box-shadow: 0 0.2rem 1rem 0 #{colors.get(neutral, 900, 0.5)};
}

@media (--small) {
    .toastContainer {
        width: 100%;
        padding: 1.6rem 1.6rem 0;
        top: 5.4rem;

        &.oranum {
            top: 6.6rem;
        }
    }

    .toast {
        margin: 0;
        width: 100%;
        min-width: unset;
        max-width: unset;
    }
}

.successIcon {
    color: var(--success-icon-color);
}

.warningIcon {
    color: var(--warning-icon-color);
}

.errorIcon {
    color: var(--error-icon-color);
}
