@use 'button';

button {
    border: none;
}

button.button.minimal {
    @include button.minimal();
}

.button {
    &.primary {
        @include button.primary();
    }

    &.secondary1 {
        @include button.secondary1();
    }

    &.secondary2 {
        @include button.secondary2();
    }

    &.textPrimary {
        @include button.textPrimary();
    }

    &.textSecondary1 {
        @include button.textSecondary1();
    }

    &.textSecondary2 {
        @include button.textSecondary2();
    }

    &.linkPrimary {
        @include button.linkPrimary();
    }

    &.linkSecondary1 {
        @include button.linkSecondary1();
    }

    &.linkSecondary2 {
        @include button.linkSecondary2();
    }

    &.xs {
        @include button.xs();
    }

    &.s {
        @include button.s();
    }

    &.m {
        @include button.m();
    }

    &.l {
        @include button.l();
    }

    &.xl {
        @include button.xl();
    }

    &.mobile-xs {
        @media (--mobile) {
            @include button.xs();
        }
    }

    &.mobile-s {
        @media (--mobile) {
            @include button.s();
        }
    }

    &.mobile-m {
        @media (--mobile) {
            @include button.m();
        }
    }

    &.mobile-l {
        @media (--mobile) {
            @include button.l();
        }
    }

    &.mobile-xl {
        @media (--mobile) {
            @include button.xl();
        }
    }
}

.base {
    input[type='file'] {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        opacity: 0;
        font-size: 200px;
        direction: ltr;
        cursor: pointer;
        width: 100%;
        height: 100%;
    }
}
