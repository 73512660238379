@use 'global/colors';

.livejasmin {
    /* 9 variants of the primary color */
    --color-primary-0: 255, 204, 204; /* #ffcccc cosmos */
    --color-primary-1: 255, 191, 191; /* #ffbfbf your-pink */
    --color-primary-2: 249, 159, 153; /* #f99f99 rose-bud */
    --color-primary-3: 237, 122, 122; /* #ed7a7a apricot */
    --color-primary-4: 218, 0, 0; /* #da0000 red */
    --color-primary-5: 192, 0, 0; /* #c00000 thunderbird */
    --color-primary-6: 166, 0, 0; /* #a60000 bright-red */
    --color-primary-7: 147, 0, 0; /* #930000 red-berry */
    --color-primary-8: 123, 0, 0; /* #7b0000 maroon */
    --color-primary-9: 72, 0, 0; /* #480000 temptress */
    --color-primary-10: 102, 0, 0; /* #660000 lonestar */
    --color-primary-11: 80, 0, 0; /* #500000 rosewood */
    --color-primary-12: 199, 0, 0; /* #c70000 box-shadow-red */
    --color-primary-13: 183, 0, 0; /* #b70000 guardsman-red */
    --color-primary-14: 255, 153, 153; /* #ff9999 mona-lisa */
    --color-primary-15: 50, 0, 0; /* #320000 temptress-dark */
    --color-primary-16: 138, 77, 77; /* #8a4d4d copper-rust */
    --color-primary-17: 202, 70, 70; /* #ca4646 mojo */
    --color-primary-18: 32, 0, 0; /* #200000 diesel */ /* used only in Overlay */
    --color-primary-19: 151, 55, 56; /* #973738 el-salva */
    --color-primary-20: 176, 108, 108; /* #b06c6c coral-tree used only in Messenger */
    --color-primary-21: 97, 0, 0; /* #610000 rosewood-light */
    --color-primary-22: 60, 0, 0; /* #3c0000 sangria */

    /* aliases for the most widely used */
    --color-primary: var(--color-primary-9);
    --color-primary-active: var(--color-primary-6);
    /* Different variants of secondary color */
    --color-secondary-1: 255, 204, 0; /* #ffcc00 sunshine */
    --color-secondary-2: 255, 219, 77; /* #ffdb4d mustard-dark */
    --color-secondary-3: 252, 148, 3; /* #fc9403 used in button */
    --color-secondary-4: 255, 197, 44; /* #ffc52c sunglow */
    --color-secondary-5: 253, 169, 53; /* #fda935 sunshade */
    --color-secondary-6: 251, 205, 0; /* #fbcd00 supernova */ /* Attention! Nearly the same as sunshine! */
    --color-secondary-7: 249, 135, 6; /* #f98706 */
    /* gradients */
    /* your-pink -> apricot */
    --gradient-primary: linear-gradient(180deg, rgb(var(--color-secondary-1)), rgb(var(--color-secondary-3)));
    --gradient-primary-active: linear-gradient(180deg, rgb(var(--color-secondary-4)), rgb(var(--color-secondary-5)));
    /* maroon -> lonestar */
    --gradient-secondary: linear-gradient(180deg, rgb(var(--color-primary-8)) 0, rgb(var(--color-primary-10)));
    --gradient-secondary-active: linear-gradient(180deg, rgb(var(--color-primary-7)) 0, rgb(var(--color-primary-8)));
    /* limeade -> limeade-dark */
    --gradient-success: linear-gradient(90deg, rgb(var(--color-success)) 0%, #739c00 100%);
    --gradient-silver: linear-gradient(180deg, #d1d1d1 0%, #848a8f 100%);
    --gradient-bronze: linear-gradient(180deg, #e3a950 0%, #9e5531 100%);
    --gradient-icon: linear-gradient(0, rgb(var(--color-secondary-7)), rgb(var(--color-secondary-1)));
    /* alias for the most widely used */
    --color-secondary: var(--color-secondary-1);
    --color-secondary-active: var(--color-secondary-1);
    /* text colors */
    --color-text: var(--color-white);
    --color-text-muted: var(--color-primary-3);
    /* the one used on NON white BG */
    --color-text-inversed: var(--color-white);
    --color-text-inversed-muted: var(--color-primary-2);
    /* the one used on white BG, e.g. breadcrumbs */
    --color-label: var(--color-white);
    --color-label-muted: var(--color-primary-2);
    /* link colors */
    --color-link: var(--color-secondary);
    --color-link-active: var(--color-white);
    --color-link-inversed: var(--color-secondary);
    --color-link-inversed-active: var(--color-link-active);
    /* background colors */
    --color-background: var(--color-primary-10);
    --color-background-container: var(--color-primary-active);
    --color-background-widget: var(--color-primary-8);

    /* used for green "success" messages */
    --color-success: 133, 212, 0; /* #85d400 limeade */
    /* color code used: http://www.ilpi.com/msds/ref/gifs/signalwordsdeprecated.jpg */
    /* used for red "error" messages */
    --color-danger: var(--color-primary-4); /* #da0000 red */
    --color-new-danger: 255, 0, 0; /* #ff0000 red */
    /* used for orange "warning" messages */
    --color-warning: var(--color-primary-3); /* apricot */
    /* used for yellow "caution" messages */
    --color-caution: 255, 204, 0; /* #ffcc00 sunshine */

    /* a palette of different colors used in colourful widgets, like pie chart */
    --color-palette-1: 255, 204, 0; /* #ffcc00 */
    --color-palette-2: 249, 135, 6; /* #f98706 */
    --color-palette-3: 128, 153, 255; /* #8099ff */
    --color-palette-4: 187, 238, 0; /* #bbee00 */
    --color-palette-5: 193, 54, 228; /* #c136e4 */
    --color-palette-6: 255, 230, 128; /* #ffe680 */
    --color-palette-7: 105, 218, 195; /* #69DAC3 */
    --color-palette-8: 255, 204, 204; /* #ffcccc */
    --color-palette-9: 255, 119, 221; /* #ff77dd */
    --color-palette-10: 133, 180, 0; /* #85b400 */
    --color-palette-11: 237, 122, 122; /* #ed7a7a */
    --color-palette-12: 255, 0, 102; /* #ff0066 */
    --color-palette-13: 53, 117, 114; /* #357572 */
    --color-palette-14: 218, 198, 0; /* #dac600 */
    --color-palette-15: 87, 184, 209; /* #57b8d1 */
    --color-palette-16: 231, 92, 0; /* #e75c00 */
    --color-palette-17: 61, 135, 1; /* #3d8701 */
    --color-palette-18: 146, 233, 152; /* #92e998 */
    --color-palette-19: 255, 161, 127; /* #ffa17f */
    --color-palette-20: 147, 93, 255; /* #935dff */

    --color-border: transparent;
    --border-radius: 0.4rem;
}
