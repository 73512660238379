@use 'global/colors';
@use 'sass:map';
@use 'global/elevation';
@use 'global/icons';
@use 'global/vars';

.livejasmin {
    --mobile-backdrop: rgba(var(--color-primary-22), 0.8);
    --background-color: rgb(var(--color-primary-6));
    --header-color: #{colors.get(neutral, 100)};
    --body-color: rgb(var(--color-primary-0));
    --link-color: rgb(var(--color-secondary-1));
    --link-hover-color: #{colors.get(neutral, 100)};
    --preference-text-color: rgb(var(--color-primary-14));
    --preference-toggle-color: rgb(var(--color-primary-3));
    --infobox-text-color: rgb(var(--color-primary-14));
    --infobox-background-color: rgb(var(--color-primary-7));
}

.oranum {
    --mobile-backdrop: rgba(var(--color-primary-9), 0.8);
    --background-color: #{colors.get(neutral, 100)};
    --header-color: rgb(var(--color-primary-6));
    --body-color: rgb(var(--color-primary-4));
    --link-color: rgb(var(--color-primary-10));
    --link-hover-color: rgb(var(--color-primary-6));
    --preference-text-color: rgb(var(--color-primary-35));
    --preference-toggle-color: rgb(var(--color-primary-35));
    --infobox-text-color: rgb(var(--color-primary-4));
    --infobox-background-color: rgb(var(--color-primary-1));
}

.wrapper {
    position: fixed;
    bottom: vars.spacer(6);
    right: vars.spacer(6);
    width: 46rem;
    z-index: 999;

    @media (--mobile) {
        background-color: var(--mobile-backdrop);
        bottom: 0;
        left: 0;
        right: auto;
        width: 100%;
        height: 100%;
    }
}

.cookieDialog {
    background-color: var(--background-color);
    box-shadow: elevation.get(light_level_04);
    border-radius: vars.border-radius(1);
    position: absolute;
    bottom: 0;
    right: 0;
    padding: vars.spacer(10) vars.spacer(12);

    @media (--mobile) {
        border-radius: vars.border-radius(5) vars.border-radius(5) 0 0;
        padding: vars.spacer(8);
    }
}

.headerText {
    margin-top: 0;
    margin-bottom: vars.spacer(4);
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: bold;
    color: var(--header-color);
}

.bodyText {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: var(--body-color);
    margin-top: 0;
}

.infoText {
    display: inline-block;
    margin-bottom: vars.spacer(8);

    a {
        font-weight: bold;
        text-decoration: none;
        color: var(--link-color);

        &:hover {
            color: var(--link-hover-color);
        }
    }
}

.switchText {
    margin-top: vars.spacer(4);
}

.switchContainer {
    margin-left: auto;
    margin-right: vars.spacer(2);
}

.preferenceText {
    margin-left: auto;
    margin-right: vars.spacer(2);
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: var(--preference-text-color);
}

.preferenceToggleWrapper {
    background: none;
    border: none;
    display: inline;
    padding: 0;
}

.preferenceToggle {
    cursor: pointer;
    color: var(--preference-toggle-color);

    &.selected::before {
        content: icons.get('caret-up');
    }
}

.infoBox {
    margin-top: vars.spacer(2);
    padding: vars.spacer(2);
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: var(--infobox-text-color);
    background-color: var(--infobox-background-color);

    &.hidden {
        display: none;
    }
}

.confirmButton.confirmButton {
    display: block;
    width: 100%;
    margin-top: vars.spacer(7);
}
